import http from '@/api/request'
import { ElMessage } from 'element-plus'

/**
 * 套餐关联试题 - --登录
 * @param data
 * @returns {*}
 */
export function getAuthTopicRelationPracticeList(data) {
  return http().post('/resource/auth/topic/relation/practice/list', data)
}

/**
 * 章节所在课程套餐 - --登录
 * @param data
 * @returns {*}
 */
export function getAuthChapterRelationPriceList(data) {
  return http().post('/resource/auth/chapter/relation/price/list', data)
}

/**
 * 课程套餐价格 - --未登录
 * @param data
 * @returns {*}
 */
export function getCoursePriceList(data) {
  return http().post('/resource/api/course/price/list', data)
}

/**
 * 课程套餐价格 - --登录
 * @param data
 * @returns {*}
 */
export function getAuthCoursePriceList(data) {
  return http().post('/resource/auth/course/price/list', data)
}

/**
 * 更新状态
 * @param data
 * @returns {*}
 */
export function videoCheck(params) {
  return http().get('/resource/auth/course/video/check', { params })
}

/**
 * 更新状态
 * @param data
 * @returns {*}
 */
export function videoCheckUpdate(params) {
  return http().get('/resource/auth/course/video/check/update', { params })
}

/**
 * 删除状态
 * @param data
 * @returns {*}
 */
export function videoCheckDelete(params) {
  return http().get('/resource/auth/course/video/check/delete', { params })
}

/**
 * 分类-课程条件查询 --未登录
 * @param data
 * @returns {*}
 */
export function getCourseList(data) {
  return http().post('/resource/api/course/page', data)
}

/**
 * 分类-课程条件查询 --登录
 * @param data
 * @returns {*}
 */
export function getAuthCourseList(data) {
  return http().post('/resource/auth/course/page', data)
}

/**
 * 查询课程信息详情 -- 未登录
 * @param data
 * @returns {*}
 */
export function getCourseView(data) {
  return http().get('/resource/api/course/view', { params: data })
}

/**
 * 查询课程信息详情 -- 登录
 * @param data
 * @returns {*}
 */
export function getAuthCourseView(data) {
  return http().post('/resource/auth/course/view', data)
}

/**
 * 评论分页 -- 未登录
 * @param param
 * @param page
 * @param size
 * @returns {*}
 */
export function courseCommentPage(param = {}, page = 1, size = 10) {
  return http().post('/resource/api/goods/comment/page', { pageCurrent: page, pageSize: size, ...param })
}

/**
 * 评论添加
 * @param data
 * @returns {*}
 */
export function courseCommentSave(data) {
  return http().post('/resource/auth/goods/comment/save', data)
}

/**
 * 评论回复
 * @param data
 * @returns {*}
 */
export function courseCommentReplySave(data) {
  return http().post('/resource/auth/goods/comment/reply/save', data)
}

/**
 * 课程笔记列表 -- 未登录
 * @param param
 * @param page
 * @param size
 * @returns {*}
 */
export function courseNotesListData(param = {}, page = 1, size = 10) {
  return http().post('/resource/api/course/notes/page', { pageCurrent: page, pageSize: size, ...param })
}

/**
 * 课程笔记列表 -- 登录
 * @param param
 * @param page
 * @param size
 * @returns {*}
 */
export function courseAuthNotesListData(param = {}, page = 1, size = 10) {
  return http().post('/resource/auth/course/notes/page', { pageCurrent: page, pageSize: size, ...param })
}

/**
 * 查询课程课件列表 -- 登录
 * @param params
 * @returns {*}
 */
export function courseListCourseware(params) {
  return http().post('/resource/auth/course/list/courseware', params)
}

/**
 * 查询课程课件列表 -- 未登录
 * @param params
 * @returns {*}
 */
export function courseApiListCourseware(params) {
  return http().post('/resource/api/course/list/courseware', params)
}

/**
 * 获取学习资源信息-开始学习
 * @param data
 * @returns {*}
 */
export function courseAuthGetResource(data) {
  return http().post('/resource/auth/course/get/resource', data)
}

/**
 * 回放观看
 * @param data
 * @returns {*}
 */
export function coursePlayBackWatch(data) {
  return http().post('/resource/auth/course/play/back/watch', data)
}

/**
 * 保存学习进度
 * @param data
 * @returns {*}
 */
export function courseSaveAuthResource(data) {
  return http().post('/resource/auth/course/save', data)
}

/**
 * 添加笔记
 * @param data
 * @returns {*}
 */
export function courseAuthNotesSave(data) {
  return http().post('/resource/auth/course/notes/save', data)
}

/**
 * 课程笔记修改
 * @param data
 * @returns {*}
 */
export function courseAuthNotesEdit(data) {
  return http().put('/resource/auth/course/notes/edit', data)
}

/**
 * 课程笔记删除
 * @param params
 * @returns {*}
 */
export function courseAuthNotesDelete(params) {
  return http().delete('/resource/auth/course/notes/delete', { params })
}

/**
 * 取消公开-私密
 * @param data
 * @returns {*}
 */
export function courseAuthNotesEditOpenSet(data) {
  return http().put('/resource/auth/course/notes/edit/open/set', data)
}
/**
 * 图文课观看
 * @param params
 * @returns {*}
 */
export function courseViewImageText(params) {
  return http().post('/resource/auth/course/view/image/text', params)
}

/**
 * 素材信息预览
 * @param data
 * @returns {*}
 */
export function materialPreview(data) {
  return http().post('/resource/api/material/preview', data)
}

/**
 * 图文课观看 -- 未登录
 * @param params
 * @returns {*}
 */
export function courseViewImageTextApi(params) {
  return http().post('/resource/api/course/view/image/text', params)
}

/**
 * 查询专题课课程目录列表 --- 未登录
 * @param params
 * @returns {*}
 */
export function courseSubjectMenuView(params) {
  return http().get('/resource/api/course/view/data', { params })
}

/**
 * 查询专题课课程目录列表 --- 登录
 * @param params
 * @returns {*}
 */
export function courseAuthSubjectMenuView(params) {
  return http().get('/resource/auth/course/view/data', { params })
}

/**
 * 查询专题课课程二级目录列表 --- 未登录
 * @param params
 * @returns {*}
 */
export function courseApiSubjectMenuTreeView(params) {
  return http().get('/resource/api/course/view/tree/data', { params })
}

/**
 * 查询专题课课程二级目录列表 --- 登录
 * @param params
 * @returns {*}
 */
export function courseAuthSubjectMenuTreeView(params) {
  return http().get('/resource/auth/course/view/tree/data', { params })
}

/**
 * 查询套餐课程列表
 * @param params
 * @returns {*}
 */
export function courseTopicMenuView(params) {
  return http().get('/resource/api/course/view/project/data', { params })
}

/**
 * 查询相应平台分享图
 * @returns {*}
 */
export function shopPicList() {
  return http().get('/base/api/shop/share/pic/list/pic', {})
}

/**
 * 查询相应平台分享图 -- 登录
 * @returns {*}
 */
export function shopAuthPicList() {
  return http().get('/base/auth/shop/share/pic/list/pic', {})
}

/**
 * 课程信息搜索
 * @param data
 * @returns {*}
 */
export function courseSearch(data) {
  return http().post('/resource/api/course/search', data)
}

/**
 * 查询专题课下课时详情
 * @param data
 * @returns {*}
 */
export function projectPeriodView(data) {
  return http().post('/resource/api/course/get/project/period/view', data)
}

/**
 * 直播观看 -- 登录
 * @param data
 * @returns {*}
 */
export function courseLiveWatchUrl(data) {
  return http().post('/resource/auth/course/live/watch', data)
}

/**
 * 直播观看 -- 未登录
 * @param data
 * @returns {*}
 */
export function courseLiveWatchApi(data) {
  return http().post('/resource/api/course/live/watch', data)
}

/**
 *
 * @param data
 * @returns {*}
 */
export function courseLiveAuthWatchLeave(data) {
  return http().post('/resource/auth/course/live/leave', data)
}
/**
 * 获取课件下载链接 --登录
 * @param data
 * @returns {*}
 */
export function coursewareDownloadAuth(data) {
  return http().post('/resource/auth/course/download/courseware', data)
}

/**
 * 获取课件下载链接 --未登录
 * @param data
 * @returns {*}
 */
export function coursewareDownloadApi(data) {
  return http().post('/resource/api/course/download/courseware', data)
}

/**
 * 人脸识别接口 --登录
 * @param data
 * @returns {*}
 */
export function userFaceId(data) {
  return http().post('/scm/auth/attendance/sign/record/check', data)
}

/**
 * 人脸识别配置请求 --登录
 * @param data
 * @returns {*}
 */
export function getUserFaceIdConfig(data) {
  return http().post('/scm/auth/attendance/sign/record/check/view', data)
}

/**
 * 预约直播-课程
 * @param params
 * @returns {*}
 */
export function courseAuthAppointment(params) {
  return http().get('/resource/auth/course/appointment/course', { params })
}

/**
 * 预约直播-课程 新
 * @param params
 * @returns {*}
 */
export function courseAuthLive(data) {
  return http().post('/resource/auth/course/live/book/save', data)
}

// 课程是否已过期
export function handleBeforeValid(course) {
  if (course.courseStatus === 3) {
    ElMessage.error('课程已过期')
    return false
  } else if (course.courseStatus === 2) {
    ElMessage.error('课程未开课')
    return false
  } else if (course.courseStatus === 1) {
    ElMessage.error('课程尚未预约')
    return false
  } else {
    return true
  }
}

/**
 * 课程，试卷分类导航
 * @param params
 * @returns {*}
 */
export function getNavTypeList(params) {
  return http().get('/base/api/resource/nav/list', { params })
}

/**
 * 查询章节必修课
 * @param data
 * @returns {*}
 */
export function getCoursePeriodWatch(data) {
  return http().post('/resource/auth/course/period/watch', data)
}

/**
 * 查询用户预约直播 --登录
 * @returns {*}
 */
export function userReservationlive(params) {
  return http().get('/base/auth/page/web/detail', { params })
}

//立即支付合同信息
export function getContract(params){
  return http().post('/resource/auth/shop/contract/get',params)
}

//保存合同信息
export function saveContract(data){
  return http().post('/resource/auth/shop/user/contract/save',data)
}